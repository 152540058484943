import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/config";

const useProductPackDetails = (packId) => {
  const [productPack, setProductPack] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProductPackDetails = async () => {
      if (!packId) {
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const packDocRef = doc(db, "productPacks", packId);
        const packDoc = await getDoc(packDocRef);
        if (!packDoc.exists()) {
          throw new Error("Product pack not found");
        }
        const packData = {
          id: packDoc.id,
          ...packDoc.data(),
          doctorRef: packDoc.data().doctorRef?.path,
          doctorServiceId: packDoc.data().doctorServiceId?.path,
        };
        // console.log("Fetched product pack:", packData);
        setProductPack(packData);
        setLoading(false);
      } catch (err) {
        // console.error("Error fetching product pack details:", err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProductPackDetails();
  }, [packId]);

  return { productPack, loading, error };
};

export default useProductPackDetails;
