import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import useProductPackDetails from "../hooks/useProductPackDetails";
import useIndividualDoctor from "../hooks/useIndividualDoctor";
import useProductPackItems from "../hooks/useProductPackItems";
import useDoctorService from "../hooks/useDoctorService";
import { ChevronLeft, Package } from "lucide-react";

const ProductPackDetail = () => {
  const { packId } = useParams();
  const navigate = useNavigate();
  const {
    productPack,
    loading: packLoading,
    error: packError,
  } = useProductPackDetails(packId);
  const {
    doctor,
    loading: doctorLoading,
    error: doctorError,
  } = useIndividualDoctor(productPack?.doctorRef);
  const {
    items,
    loading: itemsLoading,
    error: itemsError,
  } = useProductPackItems(packId);
  const {
    doctorService,
    loading: serviceLoading,
    error: serviceError,
  } = useDoctorService(productPack?.doctorServiceId);

  // Function to convert service name to URL-friendly format
  const serviceNameToUrlParam = (name) => {
    return name
      ?.toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "");
  };

  const handleProfileClick = () => {
    console.log("Try");
    if (doctor && doctorService) {
      console.log(doctorService);
      const serviceUrlName = serviceNameToUrlParam(doctorService.serviceName);
      navigate(`/caretaker/${doctor.id}/${serviceUrlName}`);
    } else {
      // Fallback to previous page if doctor or doctorService is not available
      navigate(-1);
    }
  };

  if (packLoading || doctorLoading || itemsLoading || serviceLoading)
    return <div className="text-center py-10">Loading...</div>;
  if (packError)
    return <div className="text-center py-10 text-red-600">{packError}</div>;
  if (doctorError)
    return <div className="text-center py-10 text-red-600">{doctorError}</div>;
  if (itemsError)
    return <div className="text-center py-10 text-red-600">{itemsError}</div>;
  if (serviceError)
    return <div className="text-center py-10 text-red-600">{serviceError}</div>;
  if (!productPack)
    return <div className="text-center py-10">Product pack not found</div>;

  const totalSavings =
    productPack.amazonPrice && productPack.listPrice
      ? productPack.amazonPrice - productPack.listPrice
      : 0;

  const savingsPercentage =
    productPack.amazonPrice && totalSavings > 0
      ? ((totalSavings / productPack.amazonPrice) * 100).toFixed(0)
      : 0;

  const totalItems = items
    ? items.reduce((sum, item) => sum + (item.quantity || 1), 0)
    : 0;

  const handleBuyNow = () => {
    if (productPack.shopifyVariantId) {
      const checkoutUrl = `https://mymedsky.myshopify.com/cart/${productPack.shopifyVariantId}:1`;
      window.location.href = checkoutUrl;
    } else {
      console.error("No Shopify variant ID available");
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4 sm:p-6">
      <button
        onClick={handleProfileClick}
        className="flex align-middle bg-slate-100 text-slate-500 hover:bg-slate-200 transition duration-300 py-1 pl-2 pr-4 rounded-lg mb-5"
      >
        <ChevronLeft />
        Profile
      </button>
      <h1 className="text-2xl sm:text-3xl font-bold mb-4">
        {productPack.title || "Untitled Pack"}
      </h1>

      {/* Checkout Info Section */}
      <div className="bg-white border border-slate-200 shadow-lg rounded-lg overflow-hidden mb-8">
        <div className="p-6">
          {doctor && (
            <div className="flex items-center mb-4">
              <div className="w-12 h-12 rounded-full overflow-hidden mr-4 flex-shrink-0">
                <img
                  src={doctor.profileImage || "https://via.placeholder.com/100"}
                  alt={doctor.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-gray-600">
                Curated by <span className="font-semibold">{doctor.name}</span>
              </p>
            </div>
          )}
          <p className="text-gray-600 mb-6">
            {productPack.description || "No description available"}
          </p>
          {productPack.listPrice !== undefined &&
            productPack.amazonPrice !== undefined && (
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
                <div className="flex items-baseline mb-2 sm:mb-0">
                  <span className="text-3xl font-bold text-blue-600 mr-2">
                    ${productPack.listPrice.toFixed(2)}
                  </span>
                  <span className="text-lg text-gray-500 line-through">
                    ${productPack.amazonPrice.toFixed(2)} on Amazon
                  </span>
                </div>
                {totalSavings > 0 && (
                  <p className="text-blue-600 font-semibold">
                    Save ${totalSavings.toFixed(2)} ({savingsPercentage}% off)
                  </p>
                )}
              </div>
            )}
          <button
            onClick={handleBuyNow}
            className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition duration-300 flex items-center justify-center text-md font-semibold mb-4"
          >
            <Package className="w-6 mr-3" />
            Checkout
          </button>
          {doctor && (
            <p className="text-sm text-gray-500 text-center">
              {doctor.name} does <span className="underline">not</span> earn
              commission.
            </p>
          )}
        </div>
      </div>

      {/* Products Section */}
      {items && items.length > 0 && (
        <>
          <h2 className="text-xl sm:text-2xl font-semibold mb-4">
            Included Supplies ({totalItems} item{totalItems !== 1 ? "s" : ""})
          </h2>
          <div className="space-y-6">
            {items.map((item) => (
              <div
                key={item.id}
                className="bg-white border border-slate-200 shadow-md rounded-lg overflow-hidden flex flex-col sm:flex-row"
              >
                <div className="w-full h-52 sm:w-1/3 aspect-square">
                  {item.product?.image ? (
                    <img
                      src={item.product.image}
                      alt={item.product.title}
                      className="w-full h-full object-contain"
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                      <span className="text-gray-400">No image</span>
                    </div>
                  )}
                </div>
                <div className="p-4 flex-1">
                  <h3 className="text-lg font-semibold mb-2">
                    {item.product?.title || "Untitled Product"}
                  </h3>
                  <p className="text-gray-600 mb-2">
                    {item.description || "No description available"}
                  </p>
                  <div className="flex justify-between items-center mt-4">
                    <span className="text-blue-600 font-semibold">
                      Quantity: {item.quantity || 1}
                    </span>
                    {item.product?.baseQuantity && (
                      <span className="text-gray-500">
                        Amount / Container: {item.product.baseQuantity}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ProductPackDetail;
