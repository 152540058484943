import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import DoctorProfile from "./components/DoctorProfile";
import ProductPackDetail from "./components/ProductPackDetail";
import RefundPolicy from "./components/Legal/RefundPolicy";
import TermsOfService from "./components/Legal/TOS";
import PrivacyPolicy from "./components/Legal/PrivacyPolicy";
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <div className="App flex flex-col min-h-screen bg-white">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/caretaker/:doctorid" element={<DoctorProfile />}>
              <Route path=":serviceName" element={<DoctorProfile />} />
            </Route>
            <Route path="/care-pack/:packId" element={<ProductPackDetail />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </main>
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;
