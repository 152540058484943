import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Loader2 } from "lucide-react";
import DoctorHeader from "./DoctorHeader";
import ResourceList from "./ResourceList";
import useDoctors from "../hooks/useDoctors";
import useDoctorServices from "../hooks/useDoctorServices";
import useResources from "../hooks/useResources";
import Footer from "./Footer";

const DoctorProfile = () => {
  const { doctorid, serviceName } = useParams();
  const {
    doctors,
    loading: doctorsLoading,
    error: doctorsError,
  } = useDoctors();
  const {
    services,
    loading: servicesLoading,
    error: servicesError,
  } = useDoctorServices(doctorid);
  const {
    resources,
    loading: resourcesLoading,
    error: resourcesError,
  } = useResources(services);

  const doctor = doctors.find((d) => d.id === doctorid);

  const productResources = useMemo(
    () => resources.filter((resource) => resource.contentType === "product"),
    [resources]
  );

  useEffect(() => {
    if (doctor) {
      document.title = `${doctor.name} - Doctor Profile`;
    }
  }, [doctor]);

  const renderContent = () => {
    if (doctorsLoading || servicesLoading || resourcesLoading) {
      return (
        <div className="flex flex-col items-center justify-center h-64 p-4">
          <Loader2 className="w-12 h-12 text-accent animate-spin mb-4" />
          <p className="text-lg text-gray-600">Loading doctor profile...</p>
        </div>
      );
    }

    if (doctorsError || servicesError || resourcesError) {
      return (
        <div className="text-center p-4 text-red-500">
          {doctorsError || servicesError || resourcesError}
        </div>
      );
    }

    if (!doctor) {
      return <div className="text-center p-4">No doctor data available.</div>;
    }

    return (
      <>
        <Helmet>
          <title>{`${doctor.name} - MedSky`}</title>
          <meta property="og:title" content={`${doctor.name} - MedSky`} />
          <meta
            property="og:description"
            content={`View the profile of ${doctor.name} and their services.`}
          />
          <meta
            property="og:image"
            content={
              doctor.imageUrl || "https://example.com/default-doctor-image.jpg"
            }
          />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <DoctorHeader doctor={doctor} productResources={productResources} />
        <ResourceList
          resources={resources}
          services={services}
          initialServiceName={serviceName}
          productResources={productResources}
        />
      </>
    );
  };

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow overflow-auto">{renderContent()}</main>
      <Footer />
    </div>
  );
};

export default DoctorProfile;
