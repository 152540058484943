import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/config";

const useIndividualDoctor = (doctorRef) => {
  const [doctor, setDoctor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDoctor = async () => {
      if (!doctorRef) {
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const docRef = doc(db, doctorRef);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const doctorData = { id: docSnap.id, ...docSnap.data() };
          // console.log("Fetched doctor:", doctorData);
          setDoctor(doctorData);
        } else {
          setError("Doctor not found");
        }
        setLoading(false);
      } catch (err) {
        // console.error("Error fetching doctor: ", err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchDoctor();
  }, [doctorRef]);

  return { doctor, loading, error };
};

export default useIndividualDoctor;
