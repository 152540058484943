import React, { useState, useRef, useEffect } from "react";
import { HelpCircle, ChevronDown, ChevronUp } from "lucide-react";

const FAQResource = ({ resource }) => {
  const { title, textContent } = resource;
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  // Split the textContent into question and answer
  const [question, answer] = textContent.split("|").map((str) => str.trim());

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [textContent]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className="bg-white rounded-xl border border-slate-200 overflow-hidden shadow-sm cursor-pointer"
      onClick={toggleExpand}
    >
      <div className="p-4">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">
            <div className="w-10 h-10 bg-purple-100 rounded-lg flex items-center justify-center">
              <HelpCircle className="w-5 h-5 text-purple-500" />
            </div>
          </div>
          <div className="flex-grow min-w-0">
            <h3 className="text-base font-semibold text-gray-800 line-clamp-3 leading-5">
              {title || question}
            </h3>
            <p className="text-sm text-gray-500 mt-0.5">FAQ</p>
          </div>
          <div className="flex-shrink-0">
            <div className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-slate-100 text-slate-700">
              {isExpanded ? (
                <ChevronUp className="w-5 h-5" />
              ) : (
                <ChevronDown className="w-5 h-5" />
              )}
            </div>
          </div>
        </div>
        <div
          ref={contentRef}
          className="mt-2 text-sm text-gray-600 overflow-hidden transition-[max-height] duration-300 ease-in-out"
          style={{ maxHeight: isExpanded ? `${contentHeight}px` : "0" }}
        >
          <p className="font-semibold mb-2">{question}</p>
          <p>{answer}</p>
        </div>
      </div>
    </div>
  );
};

export default FAQResource;
