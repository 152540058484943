import { useState, useEffect } from "react";
import { collection, query, where, getDocs, doc } from "firebase/firestore";
import { db } from "../firebase/config";

const useResources = (services) => {
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchResources = async () => {
      if (!services || services.length === 0) {
        setResources([]);
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const resourcePromises = services.map((service) => {
          const serviceRef = doc(db, "doctorServices", service.id);
          return getDocs(
            query(
              collection(db, "resources"),
              where("doctorServiceId", "==", serviceRef)
            )
          ).then((resourcesSnapshot) =>
            resourcesSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
              serviceId: service.id,
            }))
          );
        });

        const resourcesArrays = await Promise.all(resourcePromises);
        setResources(resourcesArrays.flat());
        setLoading(false);
      } catch (err) {
        console.error("Error fetching resources:", err);
        setError("Error loading resources. Please try again later.");
        setLoading(false);
      }
    };

    fetchResources();
  }, [services]);

  return { resources, loading, error };
};

export default useResources;
