import React, { useState, useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

const VideoResource = ({ resource }) => {
  const { title, videoLink, videoPosterLink } = resource;
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [isClient, setIsClient] = useState(false);

  const isYouTubeEmbed = (link) => {
    return link.includes("youtube.com") || link.includes("youtu.be");
  };

  const getYouTubeEmbedUrl = (link) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = link.match(regExp);
    const videoId = match && match[2].length === 11 ? match[2] : null;
    return videoId ? `https://www.youtube.com/embed/${videoId}` : link;
  };

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (isClient && !isYouTubeEmbed(videoLink) && videoRef.current) {
      playerRef.current = videojs(
        videoRef.current,
        {
          controls: true,
          fluid: true,
          aspectRatio: "16:9",
          preload: "auto",
          controlBar: {
            children: ["playToggle", "progressControl", "fullscreenToggle"],
          },
          sources: [{ type: "video/mp4", src: videoLink }],
          poster:
            videoPosterLink ||
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXfWk6cN3pQy3EHaDLVHZvXFxj8WpRMIB8Aw&s",
        },
        () => {
          // Player is ready
        }
      );

      // Customize the big play button
      const player = playerRef.current;
      player.ready(() => {
        const bigPlayButton = player.getChild("bigPlayButton");
        if (bigPlayButton) {
          bigPlayButton.el().style.fontSize = "3em";
          bigPlayButton.el().style.border = "none";
          bigPlayButton.el().style.backgroundColor = "rgba(67, 115, 187, 0.7)";
        }
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, [isClient, videoLink, videoPosterLink]);

  return (
    <div className="video-resource bg-white rounded-lg overflow-hidden">
      <div className="video-wrapper rounded-lg overflow-hidden">
        {isYouTubeEmbed(videoLink) ? (
          <iframe
            src={getYouTubeEmbedUrl(videoLink)}
            className="w-full aspect-video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
            allowFullScreen
          ></iframe>
        ) : (
          isClient && (
            <div data-vjs-player>
              <video
                ref={videoRef}
                className="video-js vjs-big-play-centered"
              />
            </div>
          )
        )}
      </div>
      <div className="py-2">
        <h3 className="text-lg font-semibold">{title}</h3>
      </div>
    </div>
  );
};

export default VideoResource;
