import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useDoctors from "../hooks/useDoctors";
import { Loader2 } from "lucide-react";

const DoctorList = () => {
  const { doctors, loading, error } = useDoctors();
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const formatName = (name) => {
    if (name.startsWith("Dr.")) {
      // Remove "Dr." prefix
      const nameWithoutPrefix = name.replace(/^Dr\.\s*/i, "");

      // Split the name into parts
      const nameParts = nameWithoutPrefix.split(" ");

      // Extract last name and first name
      const lastName = nameParts.pop();
      const firstName = nameParts.join(" ");

      // Return formatted name
      return `${lastName}, ${firstName}`;
    } else {
      // If it doesn't start with "Dr.", return the name as is
      return name;
    }
  };

  // Sort doctors by formatted name
  const sortedDoctors = [...doctors].sort((a, b) => {
    return formatName(a.name).localeCompare(formatName(b.name));
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelectDoctor = (doctor) => {
    setSelectedDoctor(doctor);
    setDropdownOpen(false);
  };

  const handleSubmit = () => {
    if (selectedDoctor) {
      navigate(`/caretaker/${selectedDoctor.id}`);
    }
  };

  if (loading)
    return (
      <div className="flex justify-center items-center h-40">
        <Loader2 className="w-8 h-8 text-white animate-spin" />
      </div>
    );
  if (error)
    return (
      <div className="text-center text-red-500">
        Error loading doctors: {error}
      </div>
    );

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex items-center justify-between px-4 py-3 bg-gray-100 rounded-full cursor-pointer h-16"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <div className="flex items-center overflow-hidden">
          {selectedDoctor ? (
            <>
              <img
                src={
                  selectedDoctor.profileImage || "/path/to/default-image.jpg"
                }
                alt={selectedDoctor.name}
                className="w-10 h-10 rounded-full mr-4 object-cover flex-shrink-0"
              />
              <span className="text-gray-800 font-semibold truncate">
                {formatName(selectedDoctor.name)}
              </span>
            </>
          ) : (
            <span className="text-gray-600 font-semibold">
              Select your Doctor
            </span>
          )}
        </div>
        <svg
          className={`w-5 h-5 text-gray-600 transition-transform ml-4 flex-shrink-0 ${
            dropdownOpen ? "rotate-180" : ""
          }`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>

      {dropdownOpen && (
        <div className="absolute w-full mt-2 bg-white border border-gray-200 rounded-2xl shadow-lg z-10 overflow-hidden">
          <ul className="max-h-80 overflow-y-auto py-2">
            {sortedDoctors.map((doctor) => (
              <li
                key={doctor.id}
                className="flex items-center px-4 py-3 hover:bg-gray-100 cursor-pointer transition-colors duration-200"
                onClick={() => handleSelectDoctor(doctor)}
              >
                <img
                  src={doctor.profileImage || "/path/to/default-image.jpg"}
                  alt={doctor.name}
                  className="w-10 h-10 rounded-full mr-4 object-cover flex-shrink-0"
                />
                <div className="overflow-hidden">
                  <span className="font-semibold text-gray-800 block truncate">
                    {formatName(doctor.name)}
                  </span>
                  <p className="text-sm text-gray-600 truncate">
                    {doctor.specialty}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      <button
        className={`mt-4 w-full py-3 rounded-full font-bold ${
          selectedDoctor
            ? "bg-white text-accent hover:bg-gray-100"
            : "bg-gray-300 text-gray-500 cursor-not-allowed"
        } transition-colors duration-200`}
        disabled={!selectedDoctor}
        onClick={handleSubmit}
      >
        Continue
      </button>
    </div>
  );
};

export default DoctorList;
