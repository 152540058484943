import React, { useEffect } from "react";
import DoctorList from "./DoctorList";

const Home = () => {
  useEffect(() => {
    // Disable scrolling
    document.body.style.overflow = "hidden";

    // Re-enable scrolling when the component is unmounted
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-accent to-accent-dark text-white">
      <div className="container mx-auto px-4 py-12 max-w-2xl">
        <h1 className="text-4xl font-bold text-center mb-2">
          Find Your Caretaker
        </h1>
        <p className="text-center mb-12 text-lg">
          Select a doctor to view their profile and services
        </p>
        <div className="">
          <DoctorList />
        </div>
      </div>
    </div>
  );
};

export default Home;
