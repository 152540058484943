import { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase/config";

const useDoctorServices = (doctorId) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDoctorServices = async () => {
      if (!doctorId) {
        setLoading(false);
        return;
      }

      try {
        const servicesQuery = query(
          collection(db, "doctorServices"),
          where("doctorId", "==", doctorId)
        );
        const querySnapshot = await getDocs(servicesQuery);
        const serviceList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setServices(serviceList);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching doctor services: ", err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchDoctorServices();
  }, [doctorId]);

  return { services, loading, error };
};

export default useDoctorServices;
