import React from "react";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <Helmet>
        <title>Privacy Policy - MedSky</title>
      </Helmet>
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <div className="prose">
        <p className="text-sm text-gray-600 mb-4">
          Last Updated: October 14, 2024
        </p>

        <p>
          At MedSky ("we," "us," or "our"), we are committed to protecting your
          privacy. This Privacy Policy explains how we collect, use, and
          safeguard your information when you use our website
          https://mymedsky.com (the "Site").
        </p>

        <h2>1. Information We Collect</h2>
        <p>
          We collect payment information via Shopify for the purpose of
          processing orders for our Recovery Packs. We also use web cookies to
          collect non-personal data to improve our services and user experience.
        </p>

        <h2>2. Use of Information</h2>
        <p>
          The information we collect is used solely for the purpose of order
          processing. We do not use your personal information for any other
          purposes without your explicit consent.
        </p>

        <h2>3. Data Sharing</h2>
        <p>
          We do not share your personal data with any third parties, except as
          necessary to process your orders or as required by law.
        </p>

        <h2>4. Data Security</h2>
        <p>
          We implement appropriate technical and organizational measures to
          protect your personal information against unauthorized or unlawful
          processing, accidental loss, destruction, or damage.
        </p>

        <h2>5. Your Rights</h2>
        <p>
          You have the right to access, correct, or delete your personal
          information. If you wish to exercise these rights, please contact us
          at hello@mymedsky.com.
        </p>

        <h2>6. SMS/Text Messaging</h2>
        <p>
          If you provide your mobile phone number, we may send you text messages
          related to your orders or services. We do not share or sell your
          mobile number or any related messaging data to third parties. To opt
          out of text messages, reply STOP to any message we send you, or email
          hello@mymedsky.com with your opt-out request. Message and data rates
          may apply.
        </p>

        <h2>7. Cookies</h2>
        <p>
          We use cookies to collect non-personal data. You can set your browser
          to refuse all or some browser cookies, or to alert you when websites
          set or access cookies.
        </p>

        <h2>8. Children's Privacy</h2>
        <p>
          We do not knowingly collect any personal information from children
          under the age of 13. If you are under 13, please do not provide any
          information on this Site.
        </p>

        <h2>9. Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by sending an email to the address you have provided
          us.
        </p>

        <h2>10. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at hello@mymedsky.com.
        </p>

        <p>
          By using the Site, you acknowledge that you have read and understood
          this Privacy Policy.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
