import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/config";

const useDoctorService = (doctorServiceRef) => {
  const [doctorService, setDoctorService] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDoctorService = async () => {
      if (!doctorServiceRef) {
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const docRef = doc(db, doctorServiceRef);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const doctorServiceData = { id: docSnap.id, ...docSnap.data() };
          // console.log("Fetched doctor service:", doctorServiceData);
          setDoctorService(doctorServiceData);
        } else {
          setError("Doctor service not found");
        }
        setLoading(false);
      } catch (err) {
        // console.error("Error fetching doctor service: ", err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchDoctorService();
  }, [doctorServiceRef]);

  return { doctorService, loading, error };
};

export default useDoctorService;
