import React from "react";
import { Helmet } from "react-helmet";

const TermsOfService = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <Helmet>
        <title>Terms of Service - MedSky</title>
      </Helmet>
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
      <div className="prose">
        <p className="text-sm text-gray-600 mb-4">
          Last Updated: October 14, 2024
        </p>

        <p>
          Welcome to MedSky ("we," "us," or "our"). By accessing or using
          https://mymedsky.com (the "Site"), you agree to comply with and be
          bound by these Terms of Service ("Terms"). If you do not agree to
          these Terms, please do not use the Site.
        </p>

        <h2>1. Services</h2>
        <p>
          MedSky provides curated recovery packs for post-op care and
          centralized resources for doctor services and patient resources. We
          host doctor profiles free of charge and offer Recovery Packs for
          purchase.
        </p>

        <h2>2. User Conduct</h2>
        <p>
          You agree to use the Site in compliance with all applicable laws and
          these Terms. You shall not:
        </p>
        <ul>
          <li>
            Violate any local, state, national, or international law or
            regulation
          </li>
          <li>
            Interfere with or disrupt the Site or servers or networks connected
            to the Site
          </li>
          <li>
            Impersonate any person or entity or falsely state or misrepresent
            your affiliation with a person or entity
          </li>
        </ul>

        <h2>3. Intellectual Property</h2>
        <p>
          All content on the Site, including text, graphics, logos, and images,
          is the property of MedSky or its content suppliers and is protected by
          United States and international copyright laws.
        </p>

        <h2>4. Privacy</h2>
        <p>
          Your use of the Site is also governed by our Privacy Policy, available
          at https://mymedsky.com/privacy-policy.
        </p>

        <h2>5. Payments</h2>
        <p>
          Payments for Recovery Packs are processed via Shopify. By making a
          purchase, you agree to provide current, complete, and accurate
          purchase and account information.
        </p>

        <h2>6. Disclaimer of Warranties</h2>
        <p>
          The Site and its content are provided "as is" and "as available"
          without any warranties of any kind, either express or implied.
        </p>

        <h2>7. Limitation of Liability</h2>
        <p>
          MedSky shall not be liable for any indirect, incidental, special,
          consequential, or punitive damages resulting from your use of or
          inability to use the Site.
        </p>

        <h2>8. Indemnification</h2>
        <p>
          You agree to indemnify and hold MedSky harmless from any claim or
          demand, including reasonable attorneys' fees, made by any third party
          due to or arising out of your use of the Site.
        </p>

        <h2>9. Governing Law</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of the United States, without regard to its conflict of law
          provisions.
        </p>

        <h2>10. Changes to Terms</h2>
        <p>
          We reserve the right to modify these Terms at any time. We will notify
          users of any changes via email.
        </p>

        <h2>11. Contact Information</h2>
        <p>
          For any questions regarding these Terms, please contact us at
          hello@mymedsky.com.
        </p>

        <p>
          By using the Site, you acknowledge that you have read, understood, and
          agree to be bound by these Terms of Service.
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
