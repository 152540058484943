import React from "react";
import { FileText, Globe, Newspaper, Headphones, BookOpen } from "lucide-react";

const LinkResource = ({ resource }) => {
  const { title, link, contentType } = resource;

  const getIconAndSubtitle = (type) => {
    switch (type.toLowerCase()) {
      case "pdf":
        return {
          Icon: FileText,
          subtitle: "PDF Document",
          bgColor: "bg-red-100",
          iconColor: "text-red-500",
        };
      case "website":
        return {
          Icon: Globe,
          subtitle: "Website",
          bgColor: "bg-blue-100",
          iconColor: "text-blue-500",
        };
      case "blog":
        return {
          Icon: Newspaper,
          subtitle: "Blog Post",
          bgColor: "bg-green-100",
          iconColor: "text-green-500",
        };
      case "podcast":
        return {
          Icon: Headphones,
          subtitle: "Podcast",
          bgColor: "bg-purple-100",
          iconColor: "text-purple-500",
        };
      case "publication":
        return {
          Icon: BookOpen,
          subtitle: "Publication",
          bgColor: "bg-yellow-100",
          iconColor: "text-yellow-600",
        };
      default:
        return {
          Icon: Globe,
          subtitle: "Link",
          bgColor: "bg-gray-100",
          iconColor: "text-gray-500",
        };
    }
  };

  const { Icon, subtitle, bgColor, iconColor } =
    getIconAndSubtitle(contentType);

  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="block bg-white border border-slate-200 rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 ease-in-out transform hover:-translate-y-1"
    >
      <div className="p-4 flex items-center space-x-4">
        <div className="flex-shrink-0">
          <div
            className={`w-10 h-10 ${bgColor} rounded-lg flex items-center justify-center`}
          >
            <Icon className={`w-5 h-5 ${iconColor}`} />
          </div>
        </div>
        <div className="flex-grow min-w-0">
          <h3 className="text-base font-semibold text-gray-800 line-clamp-3">
            {title}
          </h3>
          <p className="text-sm text-gray-500 mt-0.5">{subtitle}</p>
        </div>
        {/* <div className="flex-shrink-0">
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-slate-100 text-slate-700">
            View
          </span>
        </div> */}
      </div>
    </a>
  );
};

export default LinkResource;
