import { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase/config";

const useProductPackItems = (packId) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProductPackItems = async () => {
      if (!packId) {
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const packRef = doc(db, "productPacks", packId);
        const itemsQuery = query(
          collection(db, "productPackItemList"),
          where("productPackRef", "==", packRef)
        );
        const itemsSnapshot = await getDocs(itemsQuery);

        const itemsWithProducts = await Promise.all(
          itemsSnapshot.docs.map(async (itemDoc) => {
            const itemData = itemDoc.data();
            if (itemData.productRef) {
              const productDoc = await getDoc(itemData.productRef);
              return {
                id: itemDoc.id,
                ...itemData,
                product: productDoc.exists()
                  ? {
                      id: productDoc.id,
                      ...productDoc.data(),
                    }
                  : null,
              };
            } else {
              return {
                id: itemDoc.id,
                ...itemData,
                product: null,
              };
            }
          })
        );

        // console.log("Fetched product pack items:", itemsWithProducts);
        setItems(itemsWithProducts);
        setLoading(false);
      } catch (err) {
        // console.error("Error fetching product pack items:", err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProductPackItems();
  }, [packId]);

  return { items, loading, error };
};

export default useProductPackItems;
