import React, { useState, useRef, useEffect } from "react";
import { FileText, ChevronDown, ChevronUp } from "lucide-react";

const TextResource = ({ resource }) => {
  const { title, textContent } = resource;
  const [isExpanded, setIsExpanded] = useState(false);
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);

  // Parse the textContent
  const [header, content] = textContent.split("|").map((str) => str.trim());

  useEffect(() => {
    if (isExpanded) {
      const contentEl = contentRef.current;
      setHeight(contentEl.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isExpanded]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="bg-white border border-slate-200 rounded-xl overflow-hidden shadow-sm">
      <div className="p-4 cursor-pointer" onClick={toggleExpand}>
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">
            <div className="w-10 h-10 bg-blue-100 rounded-lg flex items-center justify-center">
              <FileText className="w-5 h-5 text-blue-500" />
            </div>
          </div>
          <div className="flex-grow min-w-0">
            <h3 className="text-base font-semibold text-gray-800 line-clamp-1">
              {title || header}
            </h3>
            <p className="text-sm text-gray-500 mt-0.5">{header}</p>
          </div>
          <div className="flex-shrink-0">
            <div className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-slate-100 text-slate-700">
              {isExpanded ? (
                <ChevronUp className="w-5 h-5" />
              ) : (
                <ChevronDown className="w-5 h-5" />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="overflow-hidden transition-[max-height] duration-300 ease-in-out"
        style={{ maxHeight: `${height}px` }}
      >
        <div ref={contentRef} className="px-4 pb-4">
          <div className="m-2 text-sm text-gray-600">
            <p>{content}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextResource;
