import React, { useState, useMemo, useEffect, useRef } from "react";
import { Phone, MapPin, ShoppingBasket, X, Contact } from "lucide-react";
import { Link } from "react-router-dom";

const DoctorHeader = ({ doctor, productResources }) => {
  const [showContactMenu, setShowContactMenu] = useState(false);
  const popupRef = useRef(null);
  const contactButtonRef = useRef(null);

  const parsedLocations = useMemo(() => {
    const parseLocation = (loc) => {
      const [name, address, phone] = loc.split("|");
      return { name, address, phone };
    };

    if (Array.isArray(doctor.locations)) {
      return doctor.locations.map(parseLocation);
    } else if (typeof doctor.locations === "string") {
      return [parseLocation(doctor.locations)];
    }
    return [];
  }, [doctor.locations]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        !contactButtonRef.current.contains(event.target)
      ) {
        setShowContactMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleContactMenu = () => {
    setShowContactMenu(!showContactMenu);
  };

  const openGoogleMaps = (address) => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        address
      )}`,
      "_blank"
    );
  };

  const callPhoneNumber = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleBookAppointment = () => {
    if (doctor.bookingUrl) {
      window.open(doctor.bookingUrl, "_blank", "noopener,noreferrer");
    } else {
      alert("Booking URL is not available");
    }
  };

  return (
    <header className="relative">
      {/* Row 1: Profile image and action items */}
      <div className="bg-gradient-to-b from-accent from-50% to-white to-50% py-4">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between">
            {/* Doctor image */}
            <img
              src={doctor.profileImage}
              alt={doctor.name}
              className="w-32 h-32 sm:w-48 sm:h-48 md:w-40 md:h-40 rounded-full border-4 border-white object-cover"
            />

            {/* Action buttons */}
            <div className="flex space-x-6 mt-6">
              <div className="flex flex-col items-center">
                <button
                  ref={contactButtonRef}
                  onClick={toggleContactMenu}
                  className="p-3 bg-accent text-white rounded-full border-4 border-white flex items-center justify-center"
                >
                  <Contact className="w-6 h-6" />
                </button>
                <span className="text-s font-medium text-slate-400">
                  Contact
                </span>
              </div>
              {productResources.length > 0 && (
                <div className="flex flex-col items-center">
                  <Link
                    to={`/caretaker/${doctor.id}/recovery-packs`}
                    className="p-3 bg-accent text-white rounded-full border-4 border-white flex items-center justify-center"
                  >
                    <ShoppingBasket className="w-6 h-6" />
                  </Link>
                  <span className="text-s font-medium text-slate-400">
                    Shop
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Row 2: Doctor info */}
      <div className="bg-white pb-4">
        <div className="container mx-auto px-4">
          <h1 className="text-2xl font-bold flex items-center text-slate-800">
            {doctor.name}
            {/* Verified badge */}
            <svg
              className="w-6 h-6 ml-2 text-sky-500"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="10" fill="currentColor" />
              <path
                d="M16.707 8.293a1 1 0 0 0-1.414 0l-5.293 5.293-2.293-2.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l6-6a1 1 0 0 0 0-1.414z"
                fill="white"
              />
            </svg>
          </h1>
          <p className="text-slate-500">{doctor.specialty}</p>
          <p className="text-slate-500">{doctor.org}</p>
        </div>
      </div>

      {/* Contact popup menu */}
      {showContactMenu && (
        <div
          ref={popupRef}
          className="absolute right-4 top-28 bg-white border border-slate-200 rounded-2xl shadow-lg p-4 z-10 w-80"
        >
          <div className="flex justify-between items-center mb-4">
            <h3 className="font-bold text-lg">Contact Information</h3>
            <button
              onClick={() => setShowContactMenu(false)}
              className="text-slate-400 hover:text-slate-600"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          {/* Booking URL */}
          {doctor.bookingUrl && (
            <div className="mb-4">
              <h4 className="font-semibold mb-2">Book Appointment</h4>
              <button
                onClick={handleBookAppointment}
                className="w-full bg-accent text-white py-2 px-4 rounded-lg hover:bg-accent-dark transition-colors"
              >
                Book Now
              </button>
            </div>
          )}

          {/* Locations and Phone Numbers */}
          {parsedLocations.map((location, index) => (
            <div
              key={index}
              className="mb-4 last:mb-0 pb-4 last:pb-0 border-b last:border-b-0 border-slate-200"
            >
              <h4 className="font-semibold mb-2">{location.name}</h4>
              <div
                className="flex items-center mb-2 text-sm text-slate-600 cursor-pointer hover:text-accent"
                onClick={() => openGoogleMaps(location.address)}
              >
                <MapPin className="w-4 h-4 mr-2 flex-shrink-0" />
                <span>{location.address}</span>
              </div>
              <div
                className="flex items-center text-sm text-slate-600 cursor-pointer hover:text-accent"
                onClick={() => callPhoneNumber(location.phone)}
              >
                <Phone className="w-4 h-4 mr-2 flex-shrink-0" />
                <span>{location.phone}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </header>
  );
};

export default DoctorHeader;
