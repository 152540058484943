import React from "react";
import { Helmet } from "react-helmet";

const RefundPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <Helmet>
        <title>Refund Policy - MedSky</title>
      </Helmet>
      <h1 className="text-3xl font-bold mb-6">Refund Policy</h1>
      <div className="prose">
        <p className="font-semibold">
          At MedSky, we are deeply committed to ensuring our customers'
          satisfaction. We offer hassle-free returns, at no cost to you, within
          30 days of your receiving your item, excluding any used products.
        </p>

        <br></br>
        <h2>Return Eligibility:</h2>
        <p>For a successful return, all products must be:</p>
        <ul>
          <li>In the condition received.</li>
          <li>Enclosed in the original packaging.</li>
          <li>Accompanied by the receipt or proof of purchase.</li>
        </ul>

        <br></br>
        <h2>Initiating a Return:</h2>
        <p>
          To begin the return process, please email us at{" "}
          <strong>hello@mymedsky.com</strong>, detailing the reason for your
          return. Upon reviewing and accepting your return request, we will send
          you a return shipping label along with further instructions. Please
          note that unsolicited returns will not be accepted.
        </p>

        <br></br>
        <h2>Damages and Issues:</h2>
        <p>
          Please inspect your order upon reception and report any defects,
          damages, or discrepancies immediately by emailing{" "}
          <strong>hello@mymedsky.com</strong>. We aim to resolve any issues
          promptly and efficiently.
        </p>

        <br></br>
        <h2>Refunds:</h2>
        <p>
          Once we receive and inspect your returned items, we will notify you
          regarding the approval or rejection of your refund. If approved, your
          refund will be processed to your original payment method within 10
          business days. If you experience delays in receiving your refund
          beyond 15 business days post-approval, please contact us at{" "}
          <strong>hello@mymedsky.com</strong>.
        </p>

        <br></br>
        <h2>Contact Us:</h2>
        <p>
          For any questions or concerns related to returns, please do not
          hesitate to contact us at <strong>hello@mymedsky.com</strong>.
        </p>
      </div>
    </div>
  );
};

export default RefundPolicy;
