import React from "react";
import { Package } from "lucide-react";
import { Link } from "react-router-dom";

const ProductResource = ({ resource }) => {
  const { title, productPrice, textContent, productDetailsRef } = resource;

  // Parse the productPrice
  const [amazonPrice, listPrice] = productPrice
    .split("|")
    .map((price) => parseFloat(price.replace("$", "").trim()));

  // Calculate savings
  const savings = amazonPrice - listPrice;
  const savingsPercentage = (savings / amazonPrice) * 100;

  // Extract subtitle from textContent
  const subtitle = textContent || "Curated post-op supplies pack";

  // Extract the ID from the productDetailsRef
  const productPackId = productDetailsRef ? productDetailsRef.id : null;

  if (!productPackId) {
    console.error("No product pack reference found for this resource");
    return null; // Or render some fallback UI
  }

  return (
    <div className="h-auto bg-white border border-slate-200 rounded-xl overflow-hidden shadow-sm transition-all duration-300 ease-in-out flex flex-col">
      <div className="p-6 flex-grow">
        <div className="flex items-center space-x-4 mb-3">
          <div className="flex-shrink-0">
            <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center">
              <Package className="w-6 h-6 text-blue-600" />
            </div>
          </div>
          <div className="flex-grow">
            <h3 className="text-lg font-semibold text-gray-800 line-clamp-1">
              {title}
            </h3>
            <p className="text-sm text-gray-500">Care Pack</p>
          </div>
        </div>

        <p className="text-sm text-gray-600 mb-4 line-clamp-2">{subtitle}</p>

        <div className="">
          <div className="flex justify-between items-baseline mb-1">
            <span className="text-2xl font-bold text-blue-600">
              ${listPrice.toFixed(2)}
            </span>
            <span className="text-sm text-gray-500 line-through">
              ${amazonPrice.toFixed(2)} on Amazon
            </span>
          </div>
          <div className="text-sm text-blue-600 font-medium">
            Save ${savings.toFixed(2)} ({savingsPercentage.toFixed(0)}% off)
          </div>
        </div>
      </div>

      <div className="px-6 pb-6">
        <Link
          to={`/care-pack/${productPackId}`}
          className="inline-flex items-center justify-center w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
        >
          View Care Pack
        </Link>
      </div>
    </div>
  );
};

export default ProductResource;
