import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const FooterLink = ({ to, children }) => (
    <Link
      to={to}
      className="hover:underline hover:underline-offset-4 transition-all"
      onClick={(e) => {
        e.preventDefault();
        window.open(to, "_blank", "noopener,noreferrer");
      }}
    >
      {children}
    </Link>
  );

  return (
    <footer className="bg-white py-6 mt-auto">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-center items-center text-sm text-gray-400 space-x-6">
          <span>© {currentYear} MedSky</span>
          <FooterLink to="/refund-policy">Refund Policy</FooterLink>
          <FooterLink to="/terms-of-service">Terms of Service</FooterLink>
          <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
