import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const getConfig = () => {
  if (
    window.RUNTIME_CONFIG &&
    window.RUNTIME_CONFIG.FIREBASE_API_KEY !== "%%REACT_APP_FIREBASE_API_KEY%%"
  ) {
    // We're in production, use runtime config
    return {
      apiKey: window.RUNTIME_CONFIG.FIREBASE_API_KEY,
      authDomain: window.RUNTIME_CONFIG.FIREBASE_AUTH_DOMAIN,
      databaseURL: window.RUNTIME_CONFIG.FIREBASE_DATABASE_URL,
      projectId: window.RUNTIME_CONFIG.FIREBASE_PROJECT_ID,
      storageBucket: window.RUNTIME_CONFIG.FIREBASE_STORAGE_BUCKET,
      messagingSenderId: window.RUNTIME_CONFIG.FIREBASE_MESSAGING_SENDER_ID,
      appId: window.RUNTIME_CONFIG.FIREBASE_APP_ID,
      measurementId: window.RUNTIME_CONFIG.FIREBASE_MEASUREMENT_ID,
    };
  } else {
    // We're in development, use process.env
    return {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    };
  }
};

const firebaseConfig = getConfig();

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
