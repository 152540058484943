import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Info } from "lucide-react";

// Import individual resource type components
import VideoResource from "./ResourceTypes/VideoResource";
import LinkResource from "./ResourceTypes/LinkResource";
import FAQResource from "./ResourceTypes/FAQResource";
import ProductResource from "./ResourceTypes/ProductResource";
import TextResource from "./ResourceTypes/TextResource";

// Mapping of content types to components
const resourceComponents = {
  video: VideoResource,
  PDF: LinkResource,
  website: LinkResource,
  blog: LinkResource,
  podcast: LinkResource,
  publication: LinkResource,
  faq: FAQResource,
  product: ProductResource,
  text: TextResource,
};

// Preferred order of category types
const preferredCategoryOrder = [
  "Bio",
  "About Me",
  "Testimonials",
  "Curated Packs",
  "Recovery Timeline",
  "Videos",
  "Resources",
  "FAQs",
];

const ResourceList = ({
  resources,
  services,
  initialServiceName,
  productResources,
}) => {
  const navigate = useNavigate();
  const { doctorid } = useParams();
  const [selectedService, setSelectedService] = useState("");

  // Sort services alphabetically by serviceName
  const sortedServices = [...services].sort((a, b) =>
    a.serviceName.localeCompare(b.serviceName)
  );

  // Prepare dropdown options
  const dropdownOptions = [];

  // Add "About Me" if it exists
  const aboutMeService = sortedServices.find(
    (s) => s.serviceName.toLowerCase() === "about me"
  );
  if (aboutMeService) {
    dropdownOptions.push(aboutMeService);
  }

  // Add "Recovery Packs" if there are product resources
  if (productResources.length > 0) {
    dropdownOptions.push({
      id: "recovery-packs",
      serviceName: "Recovery Packs",
    });
  }

  // Add separator if we have either "About Me" or "Recovery Packs"
  if (dropdownOptions.length > 0) {
    dropdownOptions.push({ id: "separator", serviceName: "separator" });
  }

  // Add all other services
  dropdownOptions.push(
    ...sortedServices.filter((s) => s.serviceName.toLowerCase() !== "about me")
  );

  // Function to convert service name to URL-friendly format
  const serviceNameToUrlParam = (name) => {
    return name
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "");
  };

  // Function to get service ID from URL param
  const getServiceIdFromUrlParam = (param) => {
    return (
      dropdownOptions.find(
        (s) => serviceNameToUrlParam(s.serviceName) === param
      )?.id || ""
    );
  };

  // Set initial dropdown state based on URL
  useEffect(() => {
    if (initialServiceName) {
      const serviceIdFromUrl = getServiceIdFromUrlParam(initialServiceName);
      if (serviceIdFromUrl) {
        setSelectedService(serviceIdFromUrl);
      } else {
        // If the service name in the URL is invalid, navigate to the base doctor URL
        navigate(`/caretaker/${doctorid}`, { replace: true });
      }
    } else {
      setSelectedService("");
    }
  }, [initialServiceName, doctorid, navigate, dropdownOptions]);

  const handleServiceChange = (e) => {
    const serviceId = e.target.value;
    setSelectedService(serviceId);

    // Update URL without navigating
    const selectedServiceName = dropdownOptions.find(
      (s) => s.id === serviceId
    )?.serviceName;
    if (selectedServiceName) {
      const serviceUrlName = serviceNameToUrlParam(selectedServiceName);
      navigate(`/caretaker/${doctorid}/${serviceUrlName}`, { replace: true });
    } else {
      navigate(`/caretaker/${doctorid}`, { replace: true });
    }
  };

  // Filter resources based on selected service
  const filteredResources =
    selectedService === "recovery-packs"
      ? productResources
      : selectedService
      ? resources.filter((resource) => resource.serviceId === selectedService)
      : [];

  // Group resources by category
  const groupedResources = filteredResources.reduce((acc, resource) => {
    if (!acc[resource.categoryType]) {
      acc[resource.categoryType] = [];
    }
    acc[resource.categoryType].push(resource);
    return acc;
  }, {});

  // Sort resources within each category by contentType
  Object.keys(groupedResources).forEach((category) => {
    groupedResources[category].sort((a, b) =>
      a.contentType.localeCompare(b.contentType)
    );
  });

  // Sort categories based on the preferred order
  const sortedCategories = Object.keys(groupedResources).sort((a, b) => {
    const indexA = preferredCategoryOrder.indexOf(a);
    const indexB = preferredCategoryOrder.indexOf(b);
    if (indexA === -1 && indexB === -1) return a.localeCompare(b);
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  });

  return (
    <div className="container mx-auto px-4">
      <select
        value={selectedService}
        onChange={handleServiceChange}
        className="w-full md:w-96 py-2.5 px-3.5 mb-6 rounded-full border text-slate-800 border-slate-200 outline-none appearance-none text-ellipsis whitespace-nowrap"
        style={{
          background:
            "url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E') no-repeat right 15px center",
          backgroundSize: "15px",
        }}
      >
        <option value="">Select a topic</option>
        {dropdownOptions.map((option) =>
          option.id === "separator" ? (
            <option key="separator" disabled>
              ───────
            </option>
          ) : (
            <option key={option.id} value={option.id}>
              {option.serviceName}
            </option>
          )
        )}
      </select>

      {!selectedService ? (
        <div className="flex items-center justify-center p-6 bg-blue-50 rounded-lg shadow-sm">
          <Info className="w-6 h-6 text-blue-500 mr-3" />
          <p className="text-blue-500 font-medium">
            Please select your topic above
          </p>
        </div>
      ) : sortedCategories.length === 0 ? (
        <p className="text-slate-400">No resources available for this topic.</p>
      ) : (
        sortedCategories.map((category) => (
          <div key={category} className="mb-8">
            <h2 className="text-xl font-bold mb-4">{category}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 auto-rows-auto">
              {groupedResources[category].map((resource) => {
                const ResourceComponent =
                  resourceComponents[resource.contentType] || LinkResource;
                return (
                  <div key={resource.id} className="contents">
                    <div className="h-full">
                      <ResourceComponent resource={resource} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ResourceList;
